import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';


// VIEWS
import Home from '../views/HomeView.vue';
// RANDOM QUOTES
import RandomQuotes from "../views/RandomQuotes.vue";
import RandomQuotesListAuthors from "../views/RandomQuotesListAuthors.vue";
import RandomQuotesListAll from "../views/RandomQuotesListAll.vue";


const routes = [
  {
    path: '/',
    component: AppLayout, // Use the layout component for all routes except the login page
    meta: {
      requiresAuth: true, // Indicates that authentication is required for this route
    },
    children: [
      { path: '', component: Home, meta: { title: 'Home Page' } },
      // LOG SECTION
      {
        path: '/log',
        name: 'log-index',
        component: () => import('../views/Log/Logview.vue'),
        meta: { title: 'LOG' }
      },
      {
        path: '/testpage',
        name: 'testpage',
        component: () => import('../views/Test/Testpage.vue'),
        meta: { title: 'TEST PAGE' }
      },

      
      // GPT GENERATOR ROUTES
      {
        path: '/gpt/template',
        name: 'template-index',
        component: () => import('@/views/GPT/GPTTemplateIndex.vue'),
        meta: { title: 'GPT:TemplateIndex' },
      }, 
      {
        path: '/gpt/template/create',
        name: 'create-template',
        component: () => import('@/views/GPT/GPTTemplateCreate.vue'),
        meta: { title: 'GPT:TemplateCreate' },
      },
      {
        path: '/gpt/template/:templateId/edit',
        name: 'edit-template',
        component: () => import('@/views/GPT/GPTTemplateEdit.vue'),
        props: true,
        meta: { title: 'GPT:TemplateEdit' },
      },
      {
        path: '/gpt/prompt/create',
        name: 'create-prompt',
        component: () => import('@/views/GPT/GPTPromptCreate.vue'),
        meta: { title: 'GPT:PromptCreate' },
      },
      {
        path: '/gpt/prompt/create/:templateId',
        name: 'create-prompt-from-template',
        component: () => import('@/views/GPT/GPTPromptCreate.vue'),
        meta: { title: 'GPT:PromptCreate' },
      },
      {
        path: '/gpt/prompt/:promptId/edit',
        name: 'edit-prompt',
        component: () => import('@/views/GPT/GPTPromptEdit.vue'),
        props: true,
        meta: { title: 'GPT:PromptEdit' },
      },
      {
        path: '/gpt/prompt/:promptId/generate',
        name: 'generate-prompt',
        component: () => import('@/views/GPT/GPTPromptGenerate.vue'),
        props: true,
        meta: { title: 'GPT:PromptGenerate' },
      },
      {
        path: '/gpt/prompt',
        name: 'prompt-index',
        component: () => import('@/views/GPT/GPTPromptIndex.vue'),
        meta: { title: 'GPT:PromptIndex' },
      },
      // // OPEN AI ROUTES
      // {
      //   path: '/openai/',
      //   name: 'openai-index',
      //   component: () => import('../views/OpenAI/OpenAIChatIndex.vue'),
      //   meta: { title: 'OpenAI:ChatIndex' },
      // },
      // {
      //   path: '/openai/list-engines',
      //   name: 'list-engines',
      //   component: () => import('../views/OpenAI/OpenAIChatListEngines.vue'),
      //   meta: { title: 'OpenAI:ListEngines' },
      // },
      // Models Section
      {
        path: '/openai/models',
        name: 'models-list',
        component: () => import('../views/OpenAI/OpenAIModelsList.vue'),
        meta: { title: 'OpenAI:ModelsList.vue' }
      },
      {
        path: '/openai/models/add',
        name: 'model-add',
        component: () => import('../views/OpenAI/OpenAIModelAdd.vue'),
        meta: { title: 'OpenAI:ModelAdd.vue' }
      },
      {
        path: '/openai/models/:modelId/edit',
        name: 'model-edit',
        component: () => import('../views/OpenAI/OpenAIModelEdit.vue'),
        meta: { title: 'OpenAI:ModelEdit.vue' },
        props: true
      },
      // Functions Section
      {
        path: '/openai/functions',
        name: 'functions-list',
        component: () => import('../views/OpenAI/OpenAIFunctionsList.vue'),
        meta: { title: 'OpenAI:FunctionsList.vue' }
      },
      {
        path: '/openai/functions/add',
        name: 'function-add',
        component: () => import('../views/OpenAI/OpenAIFunctionAdd.vue'),
        meta: { title: 'OpenAI:FunctionAdd.vue' }
      },
      {
        path: '/openai/functions/:functionId/edit',
        name: 'function-edit',
        component: () => import('../views/OpenAI/OpenAIFunctionEdit.vue'),
        meta: { title: 'OpenAI:FunctionEdit.vue' },
        props: true
      },
      // Conversations Section
      {
        path: '/openai/conversations',
        name: 'conversations-list',
        component: () => import('../views/OpenAI/OpenAIConversationsList.vue'),
        meta: { title: 'OpenAI:ConversationsList.vue' }
      },
      {
        path: '/openai/conversations/add',
        name: 'conversation-add',
        component: () => import('../views/OpenAI/OpenAIConversationAdd.vue'),
        meta: { title: 'OpenAI:ConversationAdd.vue' }
      },
      {
        path: '/openai/conversations/:conversationId/edit',
        name: 'conversation-edit',
        component: () => import('../views/OpenAI/OpenAIConversationEdit.vue'),
        meta: { title: 'OpenAI:ConversationEdit.vue' },
        props: true
      },
      // SystemProfiles Section
      {
        path: '/openai/systemprofiles',
        name: 'systemprofiles-list',
        component: () => import('../views/OpenAI/OpenAISystemProfilesList.vue'),
        meta: { title: 'OpenAI:SystemProfilesList.vue' }
      },
      {
        path: '/openai/systemprofiles/add',
        name: 'systemprofile-add',
        component: () => import('../views/OpenAI/OpenAISystemProfileAdd.vue'),
        meta: { title: 'OpenAI:SystemProfileAdd.vue' }
      },
      {
        path: '/openai/systemprofiles/:profileId/edit',
        name: 'systemprofile-edit',
        component: () => import('../views/OpenAI/OpenAISystemProfileEdit.vue'),
        meta: { title: 'OpenAI:SystemProfileEdit.vue' },
        props: true
      }

    ],
  },
  // { path: '/login', component: LoginPage }, // Login page route without the layout
  { path: '/random-quotes', component: RandomQuotes },
  { path: '/random-quotes/list-authors', component: RandomQuotesListAuthors },
  { path: '/random-quotes/list-all', component: RandomQuotesListAll },
  //   {
  //     path: '/pages/notfound',
  //     name: 'notfound',
  //     component: () => import('@/views/pages/NotFound.vue')
  // },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/auth/LoginPage.vue')
  },
  // {
  //     path: '/access',
  //     name: 'accessDenied',
  //     component: () => import('@/views/pages/auth/Access.vue')
  // },
  // {
  //     path: '/error',
  //     name: 'error',
  //     component: () => import('@/views/pages/auth/Error.vue')
  // }

];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Store the current route before navigating to the login page
let redirectFrom = null;

router.beforeEach((to, from, next) => {
  // Check if the user is trying to access a protected route
  if (to.meta.requiresAuth && !isLoggedIn()) {
    // Store the current route before redirecting to login
    redirectFrom = from.fullPath;
    next('/login');
  } else {
    next();
  }
});

// Simulate a function to check if the user is logged in
function isLoggedIn() {
  // Perform an AJAX request to check the login state on the server
  return fetch('/api/loginStatus', {
    method: 'GET',
    credentials: 'include', // Include cookies if your backend uses sessions
  })
    .then((response) => {
      if (response.success) {
        // User is authenticated on the server
        return true;
      } else {
        // User is not authenticated on the server
        return false;
      }
    })
    .catch((error) => {
      console.error('Error checking login state:', error);
      return false;
    });
}

export function redirectToPreviousRouteOrDefault() {
  if (redirectFrom) {
    // Redirect to the stored route
    router.replace(redirectFrom);
  } else {
    // Redirect to a default route after login
    router.replace('/');
  }
}

export default router;
