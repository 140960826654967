<template>
    <div class="card flex align-items-center justify-content-center">
        <Card style="min-width:50vw">
            <template #header>
            </template>
            <template #title></template>
            <template #subtitle></template>
            <template #content>
                <Blockquote class="quote-secondary">
                    <p @click="copyToClipboardWithFlash('quote')">{{ quotesData.quote }}</p>
                    <br />
                    <p @click="copyToClipboardWithFlash('author_short_description')">
                        {{ quotesData.author }}, {{ quotesData.short_description }}
                    </p>
                    <br />
                    <small @click="copyToClipboardWithFlash('author')">{{ quotesData.author }}</small>
                    <br />
                    <small @click="copyToClipboardWithFlash('profession')">
                        <cite title="Profession">{{ quotesData.profession }}</cite>
                    </small>
                    <br />
                    <small @click="copyToClipboardWithFlash('short_description')">
                        <cite title="Short Description">{{ quotesData.short_description }}</cite>
                    </small>
                </Blockquote>
            </template>
            <template #footer>
                <Button type="button" label="Regenerate" @click="fetchQuote">Generate New Quote</Button>
                <br />
                <span class="float-sm-right">
                    <small>Hint: Tap on the text to copy to the clipboard</small>
                </span>
                <br />
                <span class="float-sm-right">
                    <small><a href="" @click="viewAll">See the full list</a></small>
                </span>
            </template>
        </Card>
    </div>
</template>

  
<script>
import axios from 'axios'; // Import axios library
// import { ref } from 'vue';
// import { Card, CardBody, CardFooter, Blockquote, Button } from 'primevue/card';

export default {
    name: 'QuoteList',
    data() {
        return {
            quotesData: {
                quote: '', // Initialize with empty values
                author: '',
                profession: '',
                short_description: '',
            },
            error: '',
            noDataMessage: 'No data available.',
        };
    },
    methods: {
        fetchQuote() {
            // Make API request to fetch quotes from backend
            axios
                .get('/api/randomquotes')
                .then((response) => {
                    this.quotesData = response.data[0]; // Assuming the API returns an array with one object
                    if (Object.keys(this.quotesData).length === 0) {
                        this.noDataMessage = 'No quotes found.';
                    }
                    console.log(response.data); // Check the response from the API
                })
                .catch((error) => {
                    console.error('Error fetching quotes:', error);
                    this.error = 'An error occurred while fetching data.';
                });
        },
        copyToClipboardWithFlash(dataField) {
            // Call the regular copyToClipboard method to copy the text
            this.copyToClipboard(dataField);

            // Add a CSS class to the element to apply the green flashing effect
            const element = event.target;
            element.classList.add('flash-green');

            // Remove the CSS class after a short delay (e.g., 1 second)
            setTimeout(() => {
                element.classList.remove('flash-green');
            }, 1000); // Adjust the duration of flashing as needed
        },
        copyToClipboard(dataField) {
            let textToCopy = '';

            switch (dataField) {
                case 'quote':
                    textToCopy = '"' + this.quotesData.quote + '"';
                    break;
                case 'author':
                    textToCopy = this.quotesData.author;
                    break;
                case 'short_description':
                    textToCopy = this.quotesData.short_description;
                    break;
                case 'profession':
                    textToCopy = this.quotesData.profession;
                    break;
                case 'author_short_description':
                    textToCopy = this.quotesData.author + ', ' + this.quotesData.short_description;
                    break;
                default:
                    break;
            }

            // Create a temporary text area to hold the text
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;

            // Append the temporary text area to the DOM
            document.body.appendChild(tempTextArea);

            // Select the text in the temporary text area
            tempTextArea.select();

            try {
                // Execute the copy command
                document.execCommand('copy');
            } catch (err) {
                console.error('Unable to copy to clipboard:', err);
            }

            // Remove the temporary text area from the DOM
            document.body.removeChild(tempTextArea);
        },
        viewAll() {
            this.$router.push('/random-quotes/list-all');
        },
    },
    mounted() {
        this.fetchQuote(); // Always fetch quotes when the page is mounted
    },
};
</script>
  

<style>
/* body {
    margin-top: 5%;
    line-height: 105%$px;
    font-size: 150%;
}

.flash-green {
    animation: flashAnimation 0.9s ease-out;
}

@keyframes flashAnimation {
    0% {
        color: inherit;
        background-color: transparent;
    }

    25% {
        color: rgb(32, 218, 32);
        background-color: transparent;
        font-weight: bold;
    }

    100% {
        color: inherit;
        background-color: transparent;
    } 
}*/
</style>