<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Quotes</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="example1_filter" class="dataTables_filter">
                                            <label>Search:
                                                <input type="search" v-model="searchQuery"
                                                    class="form-control form-control-sm" placeholder=""
                                                    aria-controls="example1">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <table id="example1" class="table table-bordered table-striped dataTable dtr-inline"
                                            aria-describedby="example1_info">
                                            <thead>
                                                <tr>
                                                    <th class="sorting sorting_asc" tabindex="0" aria-controls="example1"
                                                        rowspan="1" colspan="1" aria-sort="ascending"
                                                        aria-label="Rendering engine: activate to sort column descending">
                                                        #</th>
                                                    <th class="sorting" tabindex="0" aria-controls="example1" rowspan="1"
                                                        colspan="1" aria-label="Browser: activate to sort column ascending">
                                                        Quote</th>
                                                    <th class="sorting" tabindex="0" aria-controls="example1" rowspan="1"
                                                        colspan="1"
                                                        aria-label="Platform(s): activate to sort column ascending">
                                                        Author(s)</th>
                                                    <th class="sorting" tabindex="0" aria-controls="example1" rowspan="1"
                                                        colspan="1"
                                                        aria-label="Engine version: activate to sort column ascending">
                                                        Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- Display paginated quotes -->
                                                <tr v-for="quote in getCurrentPageQuotes()" :key="quote.quote_id">
                                                    <td>{{ quote.quote_id }}</td>
                                                    <td>{{ quote.quote }}</td>
                                                    <td>{{ quote.author }}</td>
                                                    <td>{{ quote.short_description }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th rowspan="1" colspan="1">#</th>
                                                    <th rowspan="1" colspan="1">Quote</th>
                                                    <th rowspan="1" colspan="1">Author</th>
                                                    <th rowspan="1" colspan="1">Description</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-sm-12 col-md-3">
                                        <div class="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                            <!-- Display current page info -->
                                            Showing {{ (currentPage - 1) * quotesPerPage + 1 }} to {{
                                                Math.min(currentPage * quotesPerPage, filteredQuotes.length) }} of {{
        filteredQuotes.length }} entries
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-9">
                                        <div class="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                            <ul class="pagination">
                                                <!-- Previous page button -->
                                                <li class="paginate_button page-item previous"
                                                    :class="{ disabled: currentPage === 1 }">
                                                    <a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0"
                                                        class="page-link"
                                                        @click.prevent="handlePageChange(currentPage - 1)">Previous</a>
                                                </li>

                                                <!-- Page numbers -->
                                                <li class="paginate_button page-item"
                                                    :class="{ active: page === currentPage }" v-for="page in totalPages"
                                                    :key="page">
                                                    <a href="#" aria-controls="example1" data-dt-idx="1" tabindex="0"
                                                        class="page-link" @click.prevent="handlePageChange(page)">{{
                                                            page }}</a>
                                                </li>

                                                <!-- Next page button -->
                                                <li class="paginate_button page-item next"
                                                    :class="{ disabled: currentPage === totalPages }">
                                                    <a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0"
                                                        class="page-link"
                                                        @click.prevent="handlePageChange(currentPage + 1)">Next</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>


    </section>
</template>

<script>
import axios from 'axios'; // Import axios library

export default {
    name: 'QuoteList',
    data() {
        return {
            quotesData: {
                quotes: [],
            },
            error: '',
            noDataMessage: 'No data available.',
            searchQuery: '', // Add the searchQuery data property
            currentPage: 1, // Add currentPage data property to track the current page
            quotesPerPage: 20, // Set the number of quotes per page
        };
    },
    methods: {
        FetchQuotes() {
            // Make API request to fetch quotes from backend
            axios
                .get('/api/randomquotes/listall')
                .then((response) => {
                    this.quotesData.quotes = response.data['quotes']; // Assuming the API returns an array of quotes
                    if (this.quotesData.quotes.length === 0) {
                        this.noDataMessage = 'No quotes found.';
                    }
                    console.log(response.data); // Check the response from the API
                })
                .catch((error) => {
                    console.error('Error fetching quotes:', error);
                    this.error = 'An error occurred while fetching data.';
                });
        },
        // Function to get the current page quotes
        getCurrentPageQuotes() {
            const startIndex = (this.currentPage - 1) * this.quotesPerPage;
            const endIndex = startIndex + this.quotesPerPage;
            return this.filteredQuotes.slice(startIndex, endIndex);
        },
        // Function to handle page change
        handlePageChange(pageNumber) {
            this.currentPage = pageNumber;
        },
    },
    computed: {
        // Create a computed property to filter quotes based on the searchQuery
        filteredQuotes() {
            const query = this.searchQuery.toLowerCase();
            return this.quotesData.quotes.filter((quote) => {
                return Object.values(quote).some((value) => {
                    return value && value.toString().toLowerCase().includes(query);
                });
            });
        },
        // Calculate the total number of pages
        totalPages() {
            return Math.ceil(this.filteredQuotes.length / this.quotesPerPage);
        },

    },
    watch: {
        // Watch for changes in the searchQuery and reset the currentPage to 1
        searchQuery() {
            this.currentPage = 1;
        },
    },
    mounted() {
        this.FetchQuotes(); // Always fetch quotes when the page is mounted
    },
};
</script>


<style>
#example1_paginate {
    float: right;
    margin-right: 0px;
}
</style>