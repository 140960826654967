<script setup>

</script>

<template>
    <div class="layout-footer">
        <span class="font-medium ml-2 pt-4">Amped Up Admin</span>
    </div>
</template>
<style lang="scss" scoped>
.layout-footer{
    padding-top:0!important;
}
</style>
