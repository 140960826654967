<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-text-width"></i>
                                Inspirational Author
                            </h3>
                        </div>
                        <div class="card-body clearfix">
                            <!-- iterate inside this block with <p> tags-->
                            <div v-for="(authorData, index) in authorsData.authors" :key="index">
                                <blockauthor class="author-secondary" @click="copyToClipboard('author', authorData.author)">
                                    <p>{{ authorData.author }}</p>
                                </blockauthor>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button @click="FetchAuthors" class="btn btn-primary">
                                Generate New Author
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'; // Import axios library

export default {
    name: 'AuthorList',
    data() {
        return {
            authorsData: {
                authors: [],
            },
            error: '',
            noDataMessage: 'No data available.',
        };
    },
    methods: {
        FetchAuthors() {
            // Make API request to fetch authors from backend
            axios
                .get('/api/randomquotes/listauthors')
                .then((response) => {
                    this.authorsData.authors = response.data['authorsData']; // Assuming the API returns an array of authors
                    if (this.authorsData.authors.length === 0) {
                        this.noDataMessage = 'No authors found.';
                    }
                    console.log(response.data); // Check the response from the API
                })
                .catch((error) => {
                    console.error('Error fetching authors:', error);
                    this.error = 'An error occurred while fetching data.';
                });
        },
        copyToClipboard(dataField) {
            let textToCopy = '';

            switch (dataField) {
                case 'author':
                    textToCopy = this.authorsData.author;
                    break;
                default:
                    break;
            }

            // Create a temporary text area to hold the text
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = textToCopy;

            // Append the temporary text area to the DOM
            document.body.appendChild(tempTextArea);

            // Select the text in the temporary text area
            tempTextArea.select();

            try {
                // Execute the copy command
                document.execCommand('copy');
            } catch (err) {
                console.error('Unable to copy to clipboard:', err);
            }

            // Remove the temporary text area from the DOM
            document.body.removeChild(tempTextArea);
        },
    },
    mounted() {
        this.FetchAuthors(); // Always fetch authors when the page is mounted
    },
};
</script>
 