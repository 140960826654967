<template>
  <div id="app">
    <!-- The router-view will render the content based on the current route -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Your global styles go here */
</style>