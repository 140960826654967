<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
// import { useRouter } from 'vue-router';

const { onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const calendarOverlay = ref(null);
const userOverlay = ref(null);
const settingsOverlay = ref(null);
// const router = useRouter();

onMounted(() => {
    bindOutsideClickListener();
    
    // Call the function initially
    checkServerStatus();
    // Set an interval to check server status every 3 seconds
    setInterval(checkServerStatus, 3000);

});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const onlineColor = ref('text-red-500');

const checkServerStatus = async () => {
  try {
    const response = await fetch("api/checkServerStatus");
    if (response.ok) {
      onlineColor.value = 'text-green-500';
    } else {
      onlineColor.value = 'text-red-500';
    }
  } catch (error) {
    onlineColor.value = 'text-red-500';
  }
};
const toggleCalendarOverlay = (event) => {
    topbarMenuActive.value = !topbarMenuActive.value;
    calendarOverlay.value.toggle(event);
}
const toggleUserOverlay = (event) => {
    topbarMenuActive.value = !topbarMenuActive.value;
    userOverlay.value.toggle(event);
}
const toggleSettingsOverlay = (event) => {
   topbarMenuActive.value = !topbarMenuActive.value;
   settingsOverlay.value.toggle(event);
}


const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const itemsUser = ref([
    {
        separator: true
    },
    {
        label: 'Quit',
        icon: 'pi pi-fw pi-power-off',
        url: '/logout'
    }
])

const itemsSettings = ref ([
    {
        label: 'PrimeVue Docs.',
        icon: 'pi pi-fw pi-prime',
        url: 'https://primevue.org',
        target: 'blank',  
    },
    {
        label: 'Sakai Template',
        icon: 'pi pi-fw pi-palette',
        url: 'https://sakai.primevue.org/',
        target: '_blank',  
    },
    ])

</script>

<template>
    <div class="layout-topbar">
        <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>

        <router-link to="/" class="layout-topbar-logo">
            <img src="/img/ampedup-swirl-pink.svg" alt="logo" />
            <span>Amped Up Admin</span>
        </router-link>


        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <div class="layout-topbar-menu" :class="topbarMenuClasses">
            <button class="p-link layout-topbar-button">
                <i class="pi pi-circle" :class="onlineColor"></i>
                <span>Server Online</span>
            </button>
            <button @click="toggleCalendarOverlay"
                class="p-link layout-topbar-button">
                <i class="pi pi-calendar"></i>
                <span>Calendar</span>
            </button>
            <button @click="toggleUserOverlay" class="p-link layout-topbar-button"  aria-haspopup="true" aria-controls="overlay_tmenu">
                <i class="pi pi-user"></i>
                <span>Profile</span>
            </button>
            <button @click="toggleSettingsOverlay"
                class="p-link layout-topbar-button">
                <i class="pi pi-cog"></i>
                <span>Settings</span>
            </button>
        </div>
    </div>

    <OverlayPanel ref="calendarOverlay">
        <p>Hello calendar!</p>
    </OverlayPanel>
    <TieredMenu ref="userOverlay" id="uoverlay_tmenu" :model="itemsUser" popup />
    <TieredMenu ref="settingsOverlay" id="soverlay_tmenu" :model="itemsSettings" popup />
</template>

<style lang="scss" scoped>
.layout-topbar .layout-topbar-logo{
    margin-left: 2em;
}
.layout-topbar .layout-menu-button{
    margin-left:0em!important;
}
.layout-topbar{
    height:4rem;
}


</style>
