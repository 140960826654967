<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'GPT Prompt Generator',
        items: [
            { label: 'GPT Templates', icon: 'pi pi-fw pi-list', to: '/gpt/template' },
            { label: 'Create New Template', icon: 'pi pi-fw pi-plus-circle', to: '/gpt/template/create' },
            { label: 'GPT Prompts', icon: 'pi pi-fw pi-list', to: '/gpt/prompt' },
            { label: 'Create New Prompt', icon: 'pi pi-fw pi-plus-circle', to: '/gpt/prompt/create' },
        ]
    },
    {
        label: 'OpenAI Chart-GPT',
        items: [
            { label: 'Conversations', icon: 'pi pi-fw pi-comments', to: '/openai/conversations' },
            { label: 'Models', icon: 'pi pi-fw pi-th-large', to: '/openai/models' },
            { label: 'System Profiles', icon: 'pi pi-fw pi-users', to: '/openai/systemprofiles' },
            { label: 'Functions', icon: 'pi pi-fw pi-wrench', to: '/openai/functions' },
        ]
    },
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
